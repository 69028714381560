<template>
  <v-dialog
    v-model="createShieldSymbolModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Cadastrar Símbolo
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          class="mx-5 mt-5"
          lazy-validation
          @submit.prevent="createShieldSymbol()"
        >
          <image-uploader
            title="Clique aqui para enviar uma imagem"
            subtitle="Tamanho recomendado: 150 por 150 pixels"
            :saving="saving"
            :fullWidth="true"
            previewWidth="150px"
            previewHeight="150px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="createShieldSymbol()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    ImageUploader: () => import('../../../components/ImageUploader.vue')
  },
  data () {
    return {
      saving: false,
      valid: true,
      symbol: {
        imageType: undefined
      },
      image: null,
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    createShieldSymbolModal: {
      get () {
        return this.$store.state.createShieldSymbolModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createShieldSymbolModal',
          value: val
        })
      }
    }
  },
  watch: {
    createShieldSymbolModal (val) {
      if (!val) {
        this.symbol = {
          imageType: ''
        }
      }
    }
  },
  methods: {
    createShieldSymbol () {
      if (this.$refs.form.validate()) {
        if (!this.symbol.imageType) {
          this.$toast.error('É necessário enviar uma imagem.')
          return
        }
        this.saving = true
        this.$http.post('/shield-symbols', this.symbol)
          .then((res) => {
            const { data } = res
            if (data.putUrl) {
              this.uploadImage(data.putUrl, this.image, this.success, this.error)
            } else {
              this.success()
            }
          })
          .catch((err) => {
            this.saving = false
            this.$toast.error(err.response.data.message)
          })
      }
    },
    success () {
      this.saving = false
      this.createShieldSymbolModal = false
      this.$emit('created')
      this.$toast.success('Cadastro realizado com sucesso.')
    },
    error () {
      this.saving = false
      this.createShieldSymbolModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.symbol.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.symbol.imageType = undefined
      this.image = null
    }
  }
}
</script>
